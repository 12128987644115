<template>
  <div v-if="props.collection" class="space-y-12 md:space-y-16">
    <div class="space-y-3 text-center md:space-y-6">
      <slot>
        <h2 class="type-heading-1" v-text="props.collection.title" />
        <p class="type-body-lg" v-text="props.collection.description" />
      </slot>
    </div>
    <div
      v-if="products.length"
      class="grid-layout grid-cols-1 gap-y-10 md:grid-cols-12 md:gap-y-14"
    >
      <div
        v-if="products.length < 3"
        :class="products.length === 1 ? 'col-span-4' : 'col-span-2'"
        class="hidden lg:block"
      />
      <div
        v-for="product in products"
        :key="product!.id"
        class="md:col-start-3 md:col-end-11 lg:col-span-4 xl:px-5"
      >
        <ProductCard :product="product" />
      </div>
      <div
        v-if="products.length < 3"
        :class="products.length === 1 ? 'col-span-4' : 'col-span-2'"
        class="hidden lg:block"
      />
    </div>
  </div>
</template>

<script lang="ts" setup>
import type { CollectionFragment } from '@/shopify-storefront'

interface ProductCollectionProps {
  collection?: CollectionFragment | null
}

const props = defineProps<ProductCollectionProps>()
const products = computed(() =>
  [...(props.collection?.products.nodes || [])].sort((a, b) =>
    a.createdAt > b.createdAt ? 1 : -1,
  ),
)
</script>
